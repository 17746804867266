@mixin container($container-max-width, $gutter) {
    max-width: $container-max-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@mixin gridContainer($container-max-width) {
    max-width: $container-max-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}