button,
input[type="submit"] {
  appearance: none;
  border-radius: 0;
  border: none;
  outline: 0;
}


.btn {
  appearance: none;
  border-radius: 0;
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  transition: none;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  &:hover,
  &:focus {
    color: white;
  }
}


.btn--rad4 {
  border-radius: 4px;
}


.btn--primary {
  background: $primary-btn-bg;
  color: $primary-btn-fontcolor;
  &:hover,
  &:focus {
    background: $primary-btn-hover-bg;
  }
}


.btn--secondary {
  border: 2px solid $secondary-btn-border-color;
  background: $secondary-btn-bg;
  color: $secondary-btn-fontcolor;

  &:hover,
  &:focus {
    background: $primary-btn-hover-bg;
    border: 2px solid $primary-btn-hover-bg;
  }
}
