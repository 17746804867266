//----------------------------------------------------- module

.speech-bubble {
  margin: 0;
  margin-bottom: ($gutter * 2);
  padding: rems(24) rems(32);
  position: relative;
  font-size: 1em;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 3rem;
    bottom: -$caret-unit;
    transform-origin: center;
    transform: rotate(90deg)
               skew(-(strip-unit($caret-unit))+deg)
               translateY($caret-unit/1.5);
  }
}


// avoid all children's content from
// overflowing bubble (i.e. parent).
.speech-bubble p {
  -ms-overflow-style: none;
  overflow: hidden;
}



//----------------------------------------------------- layout

.speech-bubble .media-obj__fig {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: rems(20);
}


.speech-bubble .media-obj__fig svg {
  &:first-of-type {
    margin-bottom: rems(15);
  }
}


.speech-bubble .media-obj__body >:first-child {
  margin-top: 0;
}



//----------------------------------------------------- quoted source

.speech-bubble cite {
  position: absolute;
  bottom: -1.875rem;
  left: 4rem;
  font-style: normal;
  font-size: context-calc(14, 16, em);
  line-height: 1.125;
  word-wrap: break-all;
  color: black;
}


.speech-bubble cite a {
  font-weight: bold;
  color: $main-color;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: black;
  }
}


.speech-bubble--rose a {
  color: black;
  font-weight: bold;
}



//----------------------------------------------------- color variation : gallery

.speech-bubble--gallery {
  background: $gallery;

  &::before {
    @include caret(
      bottom,
      ($caret-unit / 2),
      $gallery
    );
    border-top-width: $caret-unit;
  }

  &:hover {
    cursor: pointer;
    @extend .speech-bubble--rose;
  }

  &:hover::before {
    border-top-color: $secondary-col;
  }
}



//----------------------------------------------------- color variation : rose

.speech-bubble--rose {
  background: $secondary-col;
  color: black;

  &::before {
    @include caret(
      bottom,
      ($caret-unit / 2),
      $secondary-col
    );
    border-top-width: $caret-unit;
  }
}
