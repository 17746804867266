//----------------------------------------------------- flexy forms

.form-grid {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.form-group {
  display: flex;
}

.form-group .form-group__input {
  margin-bottom: 0;
}


// * Pattern: Input Add-ons

.inputaddon-field {
  flex: 1;
  order: 2;
}

.inputaddon-item {
  align-self: center;
  order: 1;
  padding: calc((10 / 16) * 1rem); // 10 / 16 = 0.625rem
}

.inputaddon-btn {
  order: 3;
}

.inputaddon-field--reverse {
  order: 2;
}

.inputaddon-item--reverse {
  order: 3;
}

.inputaddon-btn--reverse {
  order: 1;
}


// * Pattern: Inline Input-Button Combo

.form-group--inline-btn {
  flex-direction: row;
  width: 100%;
}

.form-group__input--inline-btn {
  // flex: 1;
  flex: 1 1 auto;
}



// * Layout: Button Group Inline

.form-group__btn--inline {
  flex: 1;
  width: 100%;
}

.form-group__button--inline-btn {
  flex: 1 0 auto;
}

.form-group__btn--inline:not(:first-child) {
  margin-left: calc((20 / 16) * 1rem); // 20 / 16 = 1.25rem
}


// * Errors: Styles and States

.form-group__errormsg {
  flex: 1 0 auto; // prevent height collapsing on safari
}

.has-errors-form-group {
  color: red;
}

.form-group__errormsg--cinnabar {
  background: red;
  color: white;
}
