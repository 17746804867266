//----------------------------------------------------- contexts

body > .stripe {
  @include mq(min, $max-mq) {
    display: none;
  }
}


//----------------------------------------------------- full width

.stripe--full {
  text-align: center;
}


//----------------------------------------------------- color variations

.stripe--plumb {
  padding: 0.5em 0.75em;
  background: $main-color;
  color: white;
}
