// --------------------------------------------------------
// Config
// --------------------------------------------------------

// @example : functions/_img-url.scss
// background: img-url('picture.jpg') top left no-repeat;
$img-dir: "../img/" !default;

// @example : functions/_asset-helper.scss
// Base path for assets (fonts, images...),
// should not include trailing slash
// @access public
// @type String
$asset-base-path: ".." !default;


// --------------------------------------------------------
// Media Query Mixin
// @requires mixins/_media-query.scss
// --------------------------------------------------------

$min: min-width;
$max: max-width;
$max-height: max-height;
$min-device: min-device-width;
$max-device: max-device-width;
$viewport-increment: 0.1px;

$max-w-sm: 400px;
$max-mq: 768px;
$max-w-lg: 1180px;

$small: 320px;
$medium: 769px;
$large: 1200px;

$screen-sm: 540px;
$screen-med: $medium;
$screen-lg: $large;

$comments-lg: 800px;

$main-text-col: black;

$pre-max-w-lg: $max-w-lg - $viewport-increment;
$post-max-w-lg: $max-w-lg + $viewport-increment;


// --------------------------------------------------------
// Color : Definition
// --------------------------------------------------------

$sail: #F2F7E6;
$main-color: #93C44B;
$yellow: #FDF400;
$secondary-col: #F8EE59;
$cerulean: #EC7016;
$gallery: #EBEBEB;


$selection-highlight-color: $secondary-col !default;
$global-link-color: lighten($main-color, 5%) !default;
$placeholder-color: #AAAAAA !default;

$bg-image: "full-back.png";
$bg-top-pos: 150px;
$bg-left-pos: center;
$bg-repeat: no-repeat;


// --------------------------------------------------------
// Color : Components
// --------------------------------------------------------

$body-bg: $sail;
$header-bg: $main-color;
$banner-bg: $sail;
$content-bg: white;

$nav-dropdown-bg: $main-color;
$nav-dropdown-hover-bg: $secondary-col;
$nav-dropdown-color: black;
$nav-dropdown-caret-color: white;

$primary-btn-bg: $main-color;
$primary-btn-hover-bg: $secondary-col;
$primary-btn-fontcolor: white;
$secondary-btn-border-color: white;
$secondary-btn-bg: transparent;
$secondary-btn-fontcolor: white;

$avatar-border-color: $main-color;
$avatar-winner-bg: $main-color;

$icon-bg: $main-color;
$icon-fill: white;

// @note : $search-btn-bg
// Shared w/icon bg. Change icon
// bg to change the button color.
$search-btn-bg: $icon-bg;
$input-bg: $gallery;

$card-plumb: $main-color;
$card-plumb-fontcolor: white;
$card-rose: $secondary-col;
$card-rose-fontcolor: white;
$card-cerulean: $cerulean;
$card-cerulean-fontcolor: white;
$card-gold: $yellow;
$card-gallery: $gallery;
$card-gallery-fontcolor: black;

$card-single-bg: $card-gallery;
$card-single-hover-bg: $card-rose;
$card-single-hover-color: black;

$curriculum-vitae-header-bg: rgb(245, 245, 245);
$curriculum-vitae-body-bg: #f1f1f1;
$curriculum-vitae-info-bg: white;

$status-bg: rgba(240, 255, 255, 0.57);

$comment-level1-cardbg: $gallery;
$comment-level2-cardbg: $gallery;
$comment-level1-bg: #d6d6d6;
$comment-level1-border-color: $comment-level1-cardbg;


// --------------------------------------------------------
// Spacing & Layout
// --------------------------------------------------------

$gutter: 20px;
$column: 100px;
$grid-gap: $gutter/2;


// --------------------------------------------------------
// Component: Nav Items
// --------------------------------------------------------

// @args
// target: (name, bg-pos)
$navbg: (
  ask: (
    'yellow-pill',
    '0 -54px'
  ),
  chat: (
    'pink-pill',
    '0 -209px'
  ),
  vote: (
    'blue-pill',
    '0 -364px'
  )
);



// --------------------------------------------------------
// Component: Cards
// --------------------------------------------------------

// @args
// target: (name, bg-pos, bg-pos-hover)
$student-card-icons: (
  ask: (
    'yellow',
    '0 -101px',
    '0 0'
  ),
  chat: (
    'pink',
    '0 -256px',
    '0 -155px'
  ),
  vote: (
    'blue',
    '0 -411px',
    '0 -310px'
  )
);



// --------------------------------------------------------
// Component: Speech Bubble
// --------------------------------------------------------

$caret-unit: 25px;
