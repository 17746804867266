.profiles {
  font-size: 1em;
  line-height: 1.5;
}


.profiles .card {
  padding-left: 2em;
  padding-right: 2em;
}


.profiles li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}


.profiles .media-obj__body {
  margin-top: 1em;
}


.profiles .winner {
  &::before {
    padding-bottom: 0.5rem;
  }
}


.profiles .avatar {
  border: 4px solid $main-color;
}
