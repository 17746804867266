.curriculum-vitae {
  font-size: 1em; // 16px
}


.curriculum-vitae__header {
  border-bottom: 1px solid rgba(204, 204, 204, 0.64);
	box-shadow: inset 0 1px 1px rgba(255, 251, 251, 0.76);
  padding: 2em;
  background: $curriculum-vitae-header-bg;
}


.curriculum-vitae__title {
  font-size: context-calc(32, 16, em);
}


.curriculum-vitae__header img[class*="avatar"] {
  margin-right: 1em;
}


.curriculum-vitae__body {
  padding: 2em;
  background: $curriculum-vitae-body-bg;
}


.curriculum-vitae__quote {
  font-size: 1.25em;
  padding: 10px;
  background: $sail;
}


.curriculum-vitae__body img {
  @extend .material-shadow;
  border: context-calc(5, 16, em) solid rgba(white, 0.5);
}


.curriculum-vitae li {
  &:not(:first-of-type) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}


.curriculum-vitae__data > li {
  &:not(:first-of-type) {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}


.curriculum-vitae .hide {
  height: 0;
  opacity: 0;
  // transform: scale(0);
  // transform-origin: top left;
  // transition: 300ms transform ease-in-out;
  &:target {
    height: auto;
    opacity: 1;
    // transform: scale(1);
  }
}


.curriculum-vitae .read-more {
  &:hover,
  &:focus {
    color: black;
  }
}


.curriculum-vitae .shortlinks {
  padding-top: 1em;
}


.cv .card {
  margin-bottom: 1em;
  background: $curriculum-vitae-info-bg;
}


.cv .card li {
  margin-top: 1em;
  &:not(:first-of-type) {
    margin-bottom: 1em;
  }
}
