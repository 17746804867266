//----------------------------------------------------- title module

.title {
  position: relative;
  padding-left: context-calc(44, 24, em);
  line-height: inherit;
}



//----------------------------------------------------- adjacent icons

.title .icon {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}


.title a {
  color: inherit;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
