// @requires
// mixins/size.scss

//----------------------------------------------------- site icons

.icon {
  @include size(36px, 36px);
  border-radius: 80%;
  padding: 8px;
  position: relative;
  vertical-align: sub;
  fill: $icon-fill;
  background: $icon-bg;
}



//----------------------------------------------------- caret icon

.dropdown-icon {
  @include size(20px, 20px);
  vertical-align: sub;
}



//----------------------------------------------------- heart icon

.icon-heart {
  @include size(30px, 30px);
  display: inline-block;
  position: relative;
  top: 0;
  transform: rotate(-45deg) scale(0.75);
  transform-origin: 50% 50%;
  font-size: context-calc(20, 16, em);
  line-height: 1.125;
  background-color: white;

  &::before,
  &::after {
    @include size(30px, 30px);
    border-radius: 50%;
    content: "";
    position: absolute;
    background-color: white;
  }

  &::before {
    top: -15px;
    left: 0;
  }

  &::after {
    left: 15px;
    top: 0;
  }

  &:hover {
    cursor: pointer;
  }
}


// hearted count value
.icon-heart span {
  transform: rotate(45deg);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  color: #390050;
}



//----------------------------------------------------- state

.icon-heart.js-hearted,
.icon-heart.js-hearted::before,
.icon-heart.js-hearted::after {
  background-color: #ff7e7e;
}


.icon-heart.js-hearted {
  filter: drop-shadow(-1px 1px 2px rgba(200, 0, 0, 0.9));
}


.icon-heart.js-hearted span {
  font-weight: 800;
  color: #482828;
}



//----------------------------------------------------- color variations

.icon-heart--rose {
  &::before,
  &::after {
    background: $secondary-col;
  }
}


.icon-heart--plumb {
  background: $main-color;
  &::before,
  &::after {
    background: $main-color;
  }
}


.icon-heart--plumb span {
  color: white;
}
