// $Measure-Margin
// -------------------------------------//
// divide 1 unit of measure by given font-size & return relative value

@function measure-margin($scale, $measure, $value) {
	$pixelValue: $scale/$measure;
	$remValue: $pixelValue * $font-base;

	@if $value == rem {
		@return $pixelValue + $value;
	} @else if $value == em {
		@return ($remValue/$scale) + $value;
	} @else {
		@return $remValue * 1px;
	}
}
