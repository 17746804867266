.search-results .icon {
  margin-right: 5px;
}


.search-results__item {
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}


.search-results__cite {
  margin-left: 5px;
}
