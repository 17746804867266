// @requires
// mixins/size.scss

$avatar-border-width: 4px;

.avatar {
  @include size(70px, 70px);
  border: $avatar-border-width solid $avatar-border-color;
}

.winner {
  position: relative;
  &::before {
    border-radius: 80%;
    content: 'winner';
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 80%;
    margin: auto;
    padding-bottom: rems(3);
    position: absolute;
    bottom: $avatar-border-width;
    left: 0;
    right: 0;
    width: 90%;
    z-index: 1;
    font-size: 12px;
    text-align: center;
    background: linear-gradient(to top, $avatar-winner-bg 40%, transparent 40%);
    color: white;
  }
}


.avatar--med {
  @include size(108px, 108px);
}


.avatar--round {
  border-radius: 80%;
}
