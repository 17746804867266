// Lists
// ==========================================================================

.list-reset {
	list-style-type: none;
	margin: 0;
	padding-left: 0;
}

.list-inline {
  display: flex;
}

.list-inline li {
  &:first-of-type {
    margin-left: rems(5);
  }

  &:not(:last-of-type) *::after {
    content: ',';
  }
}


// Display Helpers
// ==========================================================================

// great for elements such as links
// wrapping a block level element.
.fit-space {
  display: inline-block;
  width: 100%;
}


// Media Alignments
// ==========================================================================

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0 ;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

img[class*="align"],
img[class*="wp-image-"] {
  height: auto;
}

.wp-caption,
.wp-caption img {
  max-width: 100%;
}

img.size-full {
  max-width: 100%;
  width: auto; // Prevent stretching of full-size images with height and width attributes in IE8
}


// Padding Remover
// ==========================================================================

.no-padlr {
  padding-left: 0;
  padding-right: 0;
}


// Material Shadow
// ==========================================================================

.material-shadow {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12),
              0 1px 2px rgba(0,0,0,0.24);
}


// Hiding
// ==========================================================================

// Hide only visually, but have it available for screenreaders: h5bp.com/v
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Extends the .visuallyhidden class to allow the element to be focusable
// when navigated to via the keyboard: h5bp.com/p
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}


// Layout
// ==========================================================================

// Clearfix: contain floats
// http://cssmojo.com/the-very-latest-clearfix-reloaded
.clearfix::after {
  content: " ";
  display: block;
  clear: both;
}
