// Dont let content behind menu scroll
// when menu is open and scrolled
html.is-unscrollable {
  overflow: hidden;
}


.nav-dropdown.is-open {
  transform: scale(1);
  overflow: scroll;
}


.nav-dropdown__list .dropdown-menu.active, .menu-item-has-children:hover ul {
  display: block;
}

.nav-dropdown__list a.dropdown-toggle.active, .menu-item-has-children ul a:hover {
  outline: none;
  background-color: $nav-dropdown-hover-bg;
  color: $nav-dropdown-color;
}
