.tera, .giga, .mega, h1, .alpha, h2, .beta, h3, .gamma, h4, .delta, h5, .epsilon, .zeta, h6 {
  text-rendering: optimizeLegibility;
  line-height: 1;
  margin-top: 0;
}

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  text-shadow: none;
  background: #F8EE59;
  color: white;
}

::selection {
  text-shadow: none;
  background: #F8EE59;
  color: white;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(255, 251, 251, 0.76);
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
img,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

html,
input {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #F2F7E6;
}

body {
  background: #F2F7E6 url("../img/full-back.png") -100% 0 no-repeat;
  background-position: center 150px;
  min-width: 320px;
}

::-webkit-input-placeholder {
  color: #AAAAAA;
}

::-moz-placeholder {
  color: #AAAAAA;
}

:-ms-input-placeholder {
  color: #AAAAAA;
}

img,
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

svg {
  width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: visible;
}

a {
  transition: 0.275s all ease-in-out;
  color: #9fca5e;
  text-decoration: none;
}

a:focus, a:hover, a:active {
  color: black;
}

@font-face {
  font-family: 'dinweb-bold';
  src: url("../fonts/dinweb-bold-webfont.woff2") format("woff2"), url("../fonts/dinweb-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  word-wrap: break-word;
  font: normal 100%/1.5 Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: serif;
  line-height: 1;
  margin-top: 0;
  text-rendering: optimizeLegibility;
  font-family: 'dinweb-bold';
}

.tera {
  font-size: 7.3125em;
  margin-bottom: 0.66667em;
}

.giga {
  font-size: 5.625em;
  margin-bottom: 0.66667em;
}

.mega, h1 {
  font-size: 4.5em;
  margin-bottom: 0.66667em;
}

.alpha, h2 {
  font-size: 3.75em;
  margin-bottom: 0.66667em;
}

.beta, h3 {
  font-size: 3em;
  margin-bottom: 0.66667em;
}

.gamma, h4 {
  font-size: 2.25em;
  margin-bottom: 0.66667em;
}

.delta, h5 {
  font-size: 1.5em;
  margin-bottom: 0.66667em;
}

.epsilon {
  font-size: 1.3125em;
  margin-bottom: 0.66667em;
}

.zeta, h6 {
  font-size: 1.125em;
  margin-bottom: 0.66667em;
}

input,
abbr,
acronym,
blockquote,
code,
kbd,
q,
samp,
var {
  hyphens: none;
}

pre code {
  word-wrap: normal;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}

pre,
code {
  white-space: pre;
}

code {
  font-family: monospace;
}

@media screen and (min-width: 1180px) {
  .container {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.spacebetween {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.flexend {
  align-items: center;
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
  padding-right: 0;
}

.form-grid {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.form-group {
  display: flex;
}

.form-group .form-group__input {
  margin-bottom: 0;
}

.inputaddon-field {
  flex: 1;
  order: 2;
}

.inputaddon-item {
  align-self: center;
  order: 1;
  padding: calc((10 / 16) * 1rem);
}

.inputaddon-btn {
  order: 3;
}

.inputaddon-field--reverse {
  order: 2;
}

.inputaddon-item--reverse {
  order: 3;
}

.inputaddon-btn--reverse {
  order: 1;
}

.form-group--inline-btn {
  flex-direction: row;
  width: 100%;
}

.form-group__input--inline-btn {
  flex: 1 1 auto;
}

.form-group__btn--inline {
  flex: 1;
  width: 100%;
}

.form-group__button--inline-btn {
  flex: 1 0 auto;
}

.form-group__btn--inline:not(:first-child) {
  margin-left: calc((20 / 16) * 1rem);
}

.form-group__errormsg {
  flex: 1 0 auto;
}

.has-errors-form-group {
  color: red;
}

.form-group__errormsg--cinnabar {
  background: red;
  color: white;
}

.row {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

.row.no-grid {
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (min-width: 769px) {
  .row.no-grid {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.grid {
  float: none;
  margin-bottom: 10px;
}

@media screen and (min-width: 769px) {
  .grid {
    float: left;
    margin-bottom: 0px;
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.grid.full {
  width: 100%;
}

.grid.half {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .grid.half {
    width: 50%;
  }
}

.grid.one-third {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .grid.one-third {
    width: 33.33333%;
  }
}

.grid.two-thirds {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .grid.two-thirds {
    width: 66.66667%;
  }
}

.grid.one-quarter {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .grid.one-quarter {
    width: 25%;
  }
}

.grid.three-quarters {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .grid.three-quarters {
    width: 75%;
  }
}

.media-obj {
  display: table;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.media-obj--vcenter {
  align-items: center;
}

.media-obj--reverse .media-obj__fig {
  order: 2;
}

.media-obj--reverse .media-obj__body {
  order: 1;
}

.media-obj--center {
  align-items: center;
  justify-content: center;
}

.media-obj--hcenter {
  justify-content: center;
}

.media-obj--caption {
  flex: 1;
  flex-direction: column;
}

.media-obj__body {
  flex: 1;
}

@media screen and (min-width: 768px) {
  body > .stripe {
    display: none;
  }
}

.stripe--full {
  text-align: center;
}

.stripe--plumb {
  padding: 0.5em 0.75em;
  background: #93C44B;
  color: white;
}

.sticky-body {
  display: flex;
  flex-direction: column;
}

.sticky-container {
  display: table;
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 1180px) {
  .sticky-container {
    flex: 1 0 auto;
  }
}

.sticky-row {
  display: -moz-box;
}

.sticky-header {
  flex: none;
}

.sticky-content {
  flex: 1 0 auto;
  height: auto;
}

.sticky-footer {
  flex: none;
  vertical-align: middle;
  align-items: center;
}

.avatar {
  width: 70px;
  height: 70px;
  border: 4px solid #93C44B;
}

.winner {
  position: relative;
}

.winner::before {
  border-radius: 80%;
  content: 'winner';
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 80%;
  margin: auto;
  padding-bottom: 0.1875rem;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  width: 90%;
  z-index: 1;
  font-size: 12px;
  text-align: center;
  background: linear-gradient(to top, #93C44B 40%, transparent 40%);
  color: white;
}

.avatar--med {
  width: 108px;
  height: 108px;
}

.avatar--round {
  border-radius: 80%;
}

button,
input[type="submit"] {
  appearance: none;
  border-radius: 0;
  border: none;
  outline: 0;
}

.btn {
  appearance: none;
  border-radius: 0;
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  transition: none;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.btn:hover, .btn:focus {
  color: white;
}

.btn--rad4 {
  border-radius: 4px;
}

.btn--primary {
  background: #93C44B;
  color: white;
}

.btn--primary:hover, .btn--primary:focus {
  background: #F8EE59;
}

.btn--secondary {
  border: 2px solid white;
  background: transparent;
  color: white;
}

.btn--secondary:hover, .btn--secondary:focus {
  background: #F8EE59;
  border: 2px solid #F8EE59;
}

.card {
  padding: 0.25rem 1rem;
  font-size: 0.875em;
  line-height: 1.425;
}

.card-single {
  color: inherit;
}

@media screen and (min-width: 640px) {
  .card-single {
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: center;
  }
}

.card-single:hover {
  cursor: pointer;
}

@media screen and (min-width: 640px) {
  .card-single:hover * {
    background: #F8EE59;
    color: black;
  }
}

@media screen and (min-width: 640px) {
  .card-single > div {
    flex: 100%;
  }
}

@media screen and (min-width: 640px) {
  .flexboxtweener .card-single > div {
    flex: 1 1 auto;
  }
}

@media screen and (min-width: 640px) {
  .card-single > * ~ * {
    border-left: 4px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 8.75em;
    width: 100%;
    background: #EBEBEB;
  }
}

.card--reply {
  position: relative;
  color: black;
  background: #EBEBEB;
  z-index: 0;
}

.card--reply::before {
  border-left: 6px solid #93C44B;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.card--gallery {
  background: #EBEBEB;
  color: black;
}

.card--gallery.hover-rose:hover {
  cursor: pointer;
}

.card--plumb {
  background: #93C44B;
  color: white;
}

.card--gold {
  background: #F8EE59;
}

.card--rose, .card--gallery.hover-rose:hover {
  background: #93C44B;
  color: white;
}

.card--carulean {
  background: #EC7016;
  color: white;
}

.comment {
  position: relative;
  color: black;
}

.comments .card {
  padding: 1.5rem 2rem;
}

.comment .media-obj__body > *:first-child {
  margin-top: 0;
}

.comment .media-obj__body a {
  font-weight: bold;
  color: black;
}

.comment .media-obj__body a:hover, .comment .media-obj__body a:focus {
  color: #F8EE59;
}

.comment + .comment {
  margin-top: 1rem;
}

.comments .comment .card .media-obj__fig {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 1.25rem;
}

.comments .comment .card .media-obj__fig img {
  margin-bottom: 0.9375rem;
}

.comment-level1 .card {
  position: relative;
  background: transparent;
}

@media screen and (min-width: 800px) {
  .comment-level1 .card {
    padding-left: 7rem;
    background: #EBEBEB;
  }
}

.comment-level2 .card {
  background: #EBEBEB;
}

@media screen and (min-width: 800px) {
  .comment-level2 .card {
    padding-left: 11.8125rem;
  }
}

.comment-level2 {
  position: relative;
  padding: 0.625rem;
  padding-bottom: 0.3125rem;
}

@media screen and (min-width: 800px) {
  .comment-level2 {
    padding: 0;
    padding-bottom: 0;
  }
}

.comment-level2:not(:last-of-type) {
  padding-bottom: 0;
}

@media screen and (min-width: 800px) {
  .comment-level2:not(:last-of-type) {
    padding-bottom: initial;
  }
}

@media screen and (min-width: 800px) {
  .comment-level2 {
    padding: initial;
  }
}

.comments-level1,
.comments-level2 {
  position: relative;
}

@media screen and (min-width: 800px) {
  .comments-level1::before,
  .comments-level2::before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    background: rgba(147, 196, 75, 0.5);
    position: absolute;
    top: -15px;
    bottom: 20px;
    z-index: 1;
  }
}

.comments-level1 {
  border: 1.25rem solid #EBEBEB;
  background: #d6d6d6;
  padding-bottom: 0.3125rem;
}

@media screen and (min-width: 800px) {
  .comments-level1 {
    border: none;
    padding-bottom: inherit;
  }
}

@media screen and (min-width: 800px) {
  .comments-level1::before {
    left: 3.75rem;
  }
}

.comments-level2 {
  border: none;
}

@media screen and (min-width: 800px) {
  .comments-level2::before {
    left: 8.75rem;
  }
}

@media screen and (min-width: 800px) {
  .comment-child2.comment-level2:before {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    position: absolute;
    top: -16px;
    left: 13.5rem;
    right: 0;
    z-index: 0;
    background: rgba(147, 196, 75, 0.5);
  }
}

.user-comments__response .card {
  padding: 1.5rem 2rem;
}

.user-comments__response .card .media-obj__fig {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 0.9375rem;
}

.user-comments__response .card .media-obj__fig img {
  margin-bottom: 1.25rem;
}

.user-comments__response .card .media-obj__body > :first-child {
  margin-top: 0;
}

.comment p:not(:first-child) {
  color: black !important;
}

.counter {
  border-radius: 2px;
  display: inline-block;
  margin-bottom: 5px;
  padding: 0.125em 0.375em;
  background: white;
}

.curriculum-vitae {
  font-size: 1em;
}

.curriculum-vitae__header {
  border-bottom: 1px solid rgba(204, 204, 204, 0.64);
  box-shadow: inset 0 1px 1px rgba(255, 251, 251, 0.76);
  padding: 2em;
  background: whitesmoke;
}

.curriculum-vitae__title {
  font-size: 2em;
}

.curriculum-vitae__header img[class*="avatar"] {
  margin-right: 1em;
}

.curriculum-vitae__body {
  padding: 2em;
  background: #f1f1f1;
}

.curriculum-vitae__quote {
  font-size: 1.25em;
  padding: 10px;
  background: #F2F7E6;
}

.curriculum-vitae__body img {
  border: 0.3125em solid rgba(255, 255, 255, 0.5);
}

.curriculum-vitae li:not(:first-of-type) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.curriculum-vitae__data > li:not(:first-of-type) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.curriculum-vitae .hide {
  height: 0;
  opacity: 0;
}

.curriculum-vitae .hide:target {
  height: auto;
  opacity: 1;
}

.curriculum-vitae .read-more:hover, .curriculum-vitae .read-more:focus {
  color: black;
}

.curriculum-vitae .shortlinks {
  padding-top: 1em;
}

.cv .card {
  margin-bottom: 1em;
  background: white;
}

.cv .card li {
  margin-top: 1em;
}

.cv .card li:not(:first-of-type) {
  margin-bottom: 1em;
}

footer[role="contentinfo"] {
  padding: 1.25rem 1em;
  text-align: center;
}

@media screen and (min-width: 769px) {
  footer[role="contentinfo"] {
    text-align: left;
  }
}

@media screen and (min-width: 769px) {
  footer[role="contentinfo"] .grid.three-quarters {
    transform: translateY(-70px);
  }
}

footer[role="contentinfo"] nav {
  padding-top: 1.25rem;
  font-size: 1em;
}

footer[role="contentinfo"] nav a {
  display: inline-block;
  padding: .5em;
  color: #93C44B;
}

footer[role="contentinfo"] nav a:not(:last-of-type) {
  padding-right: 1.25rem;
}

footer[role="contentinfo"] nav a:hover, footer[role="contentinfo"] nav a:focus {
  text-decoration: underline;
  color: #93C44B;
}

@media screen and (max-width: 768px) {
  header[role="banner"] {
    margin: auto;
    padding: 1em 0.5em 0.25em;
  }
}

@media screen and (min-width: 768px) {
  header[role="banner"] > .container {
    background: #93C44B;
  }
}

@media screen and (min-width: 768px) {
  header[role="banner"] > .container {
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    max-width: 100%;
  }
}

@media screen and (min-width: 1180px) {
  header[role="banner"] .container.flex {
    padding-right: 0;
  }
}

header[role="banner"] .container > .flexend {
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1180px) {
  header[role="banner"] .container > .flexend {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

header[role="banner"] [class*="icon-ask"],
header[role="banner"] [class*="icon-chat"],
header[role="banner"] [class*="icon-vote"] {
  margin: 0;
}

header[role="banner"] .btn-menu {
  display: block;
  height: 40px;
  margin-top: 1em;
  width: 50px;
  background: transparent;
}

@media screen and (min-width: 444px) {
  header[role="banner"] .btn-menu {
    margin-top: initial;
  }
}

@media screen and (min-width: 768px) {
  header[role="banner"] .btn-menu {
    display: none;
  }
}

header[role="banner"] img[alt*="zone"] {
  display: none;
}

@media screen and (min-width: 768px) {
  header[role="banner"] img[alt*="zone"] {
    display: inline-block;
    transform: scale(0.8) translateX(0) translateY(0);
  }
}

@media screen and (min-width: 1180px) {
  header[role="banner"] img[alt*="zone"] {
    display: inline-block;
    transform: scale(0.8) translateX(-70px) translateY(-100px);
  }
}

header[role="banner"] form[role="search"] {
  display: none;
}

@media screen and (min-width: 768px) {
  header[role="banner"] form[role="search"] {
    display: inherit;
    transform: translateX(-10px);
  }
}

header[role="banner"] form[role="search"] .icon {
  width: 30px;
  height: 30px;
  padding: 5px;
}

header[role="banner"] input[type="search"] {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  background: white;
}

header[role="banner"] input[type="search"] + [class*="btn"] {
  border: 2px solid white;
}

@media screen and (max-width: 1179.9px) {
  header[role="banner"] .report-card {
    margin: auto;
    padding: 0.25em;
  }
}

@media screen and (min-width: 1180px) {
  header[role="banner"] .report-card {
    flex: 3 1 100%;
  }
}

@media screen and (max-width: 1179.9px) {
  header[role="banner"] .report__title {
    float: none !important;
    transform: none !important;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  header[role="banner"] a[href="/"] {
    display: none;
  }
}

@media screen and (max-width: 1179.9px) {
  header[role="banner"] .btn--secondary {
    display: none;
  }
}

@media screen and (min-width: 1180px) {
  header[role="banner"] .btn--secondary {
    margin-left: 10px;
    max-width: 12.5em;
    padding: 0.35714rem;
  }
}

[class*="icon-ask"],
[class*="icon-chat"],
[class*="icon-vote"] {
  width: 145px;
  height: 54px;
  margin: 0.625rem;
  transition: none;
  background-image: url("../img/sprite-raster.png");
  background-repeat: no-repeat;
}

.icon-ask-yellow {
  background-position: 0 -101px;
}

.icon-ask-yellow:hover, .icon-ask-yellow:focus {
  background-position: 0 0;
}

.icon-chat-pink {
  background-position: 0 -256px;
}

.icon-chat-pink:hover, .icon-chat-pink:focus {
  background-position: 0 -155px;
}

.icon-vote-blue {
  background-position: 0 -411px;
}

.icon-vote-blue:hover, .icon-vote-blue:focus {
  background-position: 0 -310px;
}

.icon-ask-yellow-pill {
  width: 126px;
  height: 47px;
  background-position: 0 -54px;
}

.icon-chat-pink-pill {
  width: 126px;
  height: 47px;
  background-position: 0 -209px;
}

.icon-vote-blue-pill {
  width: 126px;
  height: 47px;
  background-position: 0 -364px;
}

.icon {
  width: 36px;
  height: 36px;
  border-radius: 80%;
  padding: 8px;
  position: relative;
  vertical-align: sub;
  fill: white;
  background: #93C44B;
}

.dropdown-icon {
  width: 20px;
  height: 20px;
  vertical-align: sub;
}

.icon-heart {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  top: 0;
  transform: rotate(-45deg) scale(0.75);
  transform-origin: 50% 50%;
  font-size: 1.25em;
  line-height: 1.125;
  background-color: white;
}

.icon-heart::before, .icon-heart::after {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  content: "";
  position: absolute;
  background-color: white;
}

.icon-heart::before {
  top: -15px;
  left: 0;
}

.icon-heart::after {
  left: 15px;
  top: 0;
}

.icon-heart:hover {
  cursor: pointer;
}

.icon-heart span {
  transform: rotate(45deg);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  color: #390050;
}

.icon-heart.js-hearted,
.icon-heart.js-hearted::before,
.icon-heart.js-hearted::after {
  background-color: #ff7e7e;
}

.icon-heart.js-hearted {
  filter: drop-shadow(-1px 1px 2px rgba(200, 0, 0, 0.9));
}

.icon-heart.js-hearted span {
  font-weight: 800;
  color: #482828;
}

.icon-heart--rose::before, .icon-heart--rose::after {
  background: #F8EE59;
}

.icon-heart--plumb {
  background: #93C44B;
}

.icon-heart--plumb::before, .icon-heart--plumb::after {
  background: #93C44B;
}

.icon-heart--plumb span {
  color: white;
}

input[type="search"] + [class*="btn"] {
  border: 2px solid transparent;
  background: #93C44B;
}

input:not([type="submit"]),
select,
textarea {
  appearance: none;
  border: none;
  border-radius: 0;
  padding: 1rem;
  width: 100%;
  background: #EBEBEB;
}

input:not([type="submit"]):hover, input:not([type="submit"]):focus,
select:hover,
select:focus,
textarea:hover,
textarea:focus {
  outline: none;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  appearance: checkbox !important;
  width: auto;
}

input[type="radio"] {
  -webkit-appearance: radio !important;
  -moz-appearance: radio !important;
  appearance: radio !important;
  width: auto;
}

input[type="text"]:required {
  border: 2px solid #DD5E00;
}

select {
  cursor: pointer;
  color: #AAAAAA;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjgzIDE2LjQybDkuMTcgOS4xNyA5LjE3LTkuMTcgMi44MyAyLjgzLTEyIDEyLTEyLTEyeiIvPjxwYXRoIGQ9Ik0wLS43NWg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+);
    background-position: right 1.2% top 50%;
    background-repeat: no-repeat;
    background-size: 30px;
  }
}

.login input:not([type="submit"]),
.login textarea,
.login select {
  margin-bottom: 1rem;
}

.login input[type="search"] {
  padding: 0.83333rem;
}

.login .icon {
  border-radius: 0;
}

.nav-dropdown, .menu-header-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  text-align: right;
  background: rgba(147, 196, 75, 0.9);
  font-size: 1.25em;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .nav-dropdown, .menu-header-menu-container {
    position: relative;
    transform: scale(1);
    font-size: 1em;
    text-align: left;
    background: #93C44B;
  }
}

.nav-dropdown__list, .menu-header-menu-container ul {
  height: 100%;
  font-size: 1em;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .nav-dropdown__list, .menu-header-menu-container ul {
    display: block;
  }
}

.menu-header-menu-container ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.nav-dropdown__list li, .menu-header-menu-container ul li {
  display: block;
  list-style: none;
}

@media screen and (min-width: 768px) {
  .nav-dropdown__list li, .menu-header-menu-container ul li {
    display: inline-block;
  }
}

.nav-dropdown__list > li:not(:first-of-type), .menu-header-menu-container > ul > li:not(:first-of-type) {
  margin-left: -0.3125em;
}

.nav-dropdown__list .button-dropdown, .menu-header-menu-container ul li {
  position: relative;
}

.nav-dropdown__list .button-dropdown:hover > ul,
.nav-dropdown__list .button-dropdown:focus > ul,
.menu-header-menu-container ul li:hover ul,
.menu-header-menu-container ul li:focus ul {
  display: block;
}

.nav-dropdown__list a, .menu-header-menu-container ul a {
  display: block;
  padding: 0.625em 0.9375em;
  text-decoration: none;
  background-color: transparent;
  color: black;
}

@media screen and (min-width: 768px) {
  .nav-dropdown__list a, .menu-header-menu-container ul a {
    padding: 0.625em 0.9375em;
  }
}

.menu-item-has-children ul a {
  background: #93C44B;
}

.nav-dropdown__list a:hover,
.nav-dropdown__list a:focus,
.menu-header-menu-container a:hover,
.menu-header-menu-container a:focus {
  outline: none;
  background-color: #F8EE59;
  color: black;
}

.nav-dropdown__list .dropdown-icon {
  margin-left: 2px;
  fill: white;
}

.nav-dropdown__list a.dropdown-toggle.active .dropdown-icon {
  fill: white;
}

.nav-dropdown__list .dropdown-menu, .menu-item-has-children ul {
  display: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: right;
  background: rgba(147, 196, 75, 0.6);
  color: black;
}

@media screen and (min-width: 768px) {
  .nav-dropdown__list .dropdown-menu, .menu-item-has-children ul {
    position: absolute;
    left: 0;
    width: 150%;
    z-index: 500000;
    text-align: left;
    background: #93C44B;
  }
}

.nav-dropdown__list .dropdown-menu li, .menu-item-has-children ul li {
  min-width: 100%;
  white-space: nowrap;
}

.nav-dropdown__list .dropdown-menu a, .menu-item-has-children ul li a {
  display: inline-block;
  width: 100%;
  color: black;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav[role="navigation"] {
  transform: scale(0.75);
  flex-wrap: wrap;
  flex: 1 0 auto;
}

@media screen and (min-width: 400px) {
  nav[role="navigation"] {
    flex: initial;
    transform: scale(1);
  }
}

@media screen and (min-width: 640px) {
  .news-report:hover, .news-report:focus {
    background: #F8EE59;
  }
}

.news-report:not(:first-of-type) {
  margin-top: 1rem;
}

.news-report:last-of-type {
  margin-bottom: 1rem;
}

@media screen and (min-width: 640px) {
  .news-report > *:hover, .news-report > *:focus {
    background: #F8EE59;
    color: white;
  }
}

@media screen and (min-width: 640px) {
  .news-report > *:hover ~ * {
    background: #F8EE59;
    color: white;
  }
}

.profiles {
  font-size: 1em;
  line-height: 1.5;
}

.profiles .card {
  padding-left: 2em;
  padding-right: 2em;
}

.profiles li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.profiles .media-obj__body {
  margin-top: 1em;
}

.profiles .winner::before {
  padding-bottom: 0.5rem;
}

.profiles .avatar {
  border: 4px solid #93C44B;
}

@media screen and (max-width: 1179.9px) {
  .report {
    padding: 1.5em 1em;
    background: rgba(255, 255, 255, 0.65);
  }
}

@media screen and (min-width: 1180px) {
  .report {
    box-shadow: none !important;
  }
}

.report-card {
  max-width: 700px;
  min-height: 90px;
}

.report__data a:hover {
  cursor: pointer;
}

.report__data a:hover .avatar {
  border-color: #F8EE59;
}

.report__data a:hover .media-obj__body {
  color: black;
}

.report__title {
  float: left;
  margin-bottom: 0.625em;
  transform: translateY(150%);
  font-size: 1.25em;
  text-transform: uppercase;
  text-align: right;
}

.report__data {
  display: flex;
  align-items: center;
  align-content: stretch;
  height: 100%;
}

@media screen and (max-width: 520px) {
  .report__data {
    flex-wrap: wrap;
  }
}

.report__data .media-obj {
  align-items: center;
  margin: 0;
}

.report__data > a {
  flex: 33.333333%;
  padding: 0 0.25em;
  color: inherit;
}

@media screen and (min-width: 1180px) {
  .report__data > a {
    flex: 70px;
    padding: initial;
  }
}

.report__data .media-obj__body {
  font-weight: 800;
}

.search-results .icon {
  margin-right: 5px;
}

.search-results__item {
  display: flex;
  align-items: center;
}

.search-results__item:not(:last-of-type) {
  margin-bottom: 10px;
}

.search-results__cite {
  margin-left: 5px;
}

.speech-bubble {
  margin: 0;
  margin-bottom: 40px;
  padding: 1.5rem 2rem;
  position: relative;
  font-size: 1em;
}

.speech-bubble::before {
  content: '';
  display: block;
  position: absolute;
  left: 3rem;
  bottom: -25px;
  transform-origin: center;
  transform: rotate(90deg) skew(-25deg) translateY(16.66667px);
}

.speech-bubble p {
  -ms-overflow-style: none;
  overflow: hidden;
}

.speech-bubble .media-obj__fig {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 1.25rem;
}

.speech-bubble .media-obj__fig svg:first-of-type {
  margin-bottom: 0.9375rem;
}

.speech-bubble .media-obj__body > :first-child {
  margin-top: 0;
}

.speech-bubble cite {
  position: absolute;
  bottom: -1.875rem;
  left: 4rem;
  font-style: normal;
  font-size: 0.875em;
  line-height: 1.125;
  word-wrap: break-all;
  color: black;
}

.speech-bubble cite a {
  font-weight: bold;
  color: #93C44B;
}

.speech-bubble cite a:hover, .speech-bubble cite a:focus {
  text-decoration: underline;
  color: black;
}

.speech-bubble--rose a, .speech-bubble--gallery:hover a {
  color: black;
  font-weight: bold;
}

.speech-bubble--gallery {
  background: #EBEBEB;
}

.speech-bubble--gallery::before {
  border: 12.5px solid transparent;
  border-top: 12.5px solid #EBEBEB;
  border-bottom: 0;
  height: 0;
  width: 0;
  border-top-width: 25px;
}

.speech-bubble--gallery:hover {
  cursor: pointer;
}

.speech-bubble--gallery:hover::before {
  border-top-color: #F8EE59;
}

.speech-bubble--rose, .speech-bubble--gallery:hover {
  background: #F8EE59;
  color: black;
}

.speech-bubble--rose::before, .speech-bubble--gallery:hover::before {
  border: 12.5px solid transparent;
  border-top: 12.5px solid #F8EE59;
  border-bottom: 0;
  height: 0;
  width: 0;
  border-top-width: 25px;
}

.teacher-status {
  font-size: 1.5em;
}

.teacher-status .avatar {
  border: 0.20833em solid #93C44B;
}

.teacher-status .media-obj__body {
  margin-top: 0.5em;
  text-align: center;
}

.teacher-status .status {
  margin-bottom: 0.5em;
  padding: 0.5em;
  background: rgba(240, 255, 255, 0.57);
}

.teacher-status .winner::before {
  padding-bottom: 0.5rem;
}

.title {
  position: relative;
  padding-left: 1.83333em;
  line-height: inherit;
}

.title .icon {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.title a {
  color: inherit;
}

.title a:hover, .title a:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1180px) {
  .grid.two-thirds > .row + .row {
    margin-top: 1.25rem;
  }
}

.grid.one-third hr {
  border-top: 2px solid #8E6F9C;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.grid.one-third > .row + .row {
  margin-top: 1.25rem;
}

@media screen and (min-width: 740px) and (max-width: 968px) {
  .grid.one-third .card.media-obj {
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .grid.one-third .card.media-obj {
    flex-direction: column;
  }
}

.grid.one-third .speech-bubble {
  display: flex;
  align-content: stretch;
  padding: 0.125em 1.25em;
}

@media screen and (min-width: 769px) {
  .grid.one-third .speech-bubble {
    min-height: 12.5em;
    max-height: 12.5em;
  }
}

.flexboxtweener .grid.one-third .speech-bubble {
  display: block;
}

.template-primary main > .container {
  padding: 0.83333rem;
  background: white;
}

@media screen and (min-width: 1180px) {
  .template-primary main > .container {
    padding: 1.875rem 1.25rem 7.5rem;
  }
}

html.is-unscrollable {
  overflow: hidden;
}

.nav-dropdown.is-open {
  transform: scale(1);
  overflow: scroll;
}

.nav-dropdown__list .dropdown-menu.active, .menu-item-has-children:hover ul {
  display: block;
}

.nav-dropdown__list a.dropdown-toggle.active, .menu-item-has-children ul a:hover {
  outline: none;
  background-color: #F8EE59;
  color: black;
}

.tinynav {
  display: none;
}

@media screen and (max-width: 768px) {
  .tinynav {
    display: block;
  }
  .select-nav {
    display: none;
  }
}

.list-reset {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.list-inline {
  display: flex;
}

.list-inline li:first-of-type {
  margin-left: 0.3125rem;
}

.list-inline li:not(:last-of-type) *::after {
  content: ',';
}

.fit-space {
  display: inline-block;
  width: 100%;
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

img[class*="align"],
img[class*="wp-image-"] {
  height: auto;
}

.wp-caption,
.wp-caption img {
  max-width: 100%;
}

img.size-full {
  max-width: 100%;
  width: auto;
}

.no-padlr {
  padding-left: 0;
  padding-right: 0;
}

.material-shadow, .curriculum-vitae__body img, .report, .teacher-status .status {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.clearfix::after, .row::after {
  content: " ";
  display: block;
  clear: both;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
