// @requires
// vars/globals.scss
// mixins/media-query.scss
// functions/rems.scss

//----------------------------------------------------- layout

header[role="banner"] {
  @include mq(max, $max-mq) {
    margin: auto;
    padding: 1em 0.5em 0.25em;
  }
}


// Primary Container
header[role="banner"] > .container {
  @include mq(min, $max-mq) {
    background: $header-bg;
  }
  @include mq(min, $max-mq) {
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    max-width: 100%;
  }
}


header[role="banner"] .container.flex {
  @include mq(min, $max-w-lg) {
    padding-right: 0;
  }
}


header[role="banner"] .container > .flexend {
  justify-content: center;
  flex-wrap: wrap;
  @include mq(min, $max-w-lg) {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}


//----------------------------------------------------- components

// Nav Items
header[role="banner"] [class*="icon-ask"],
header[role="banner"] [class*="icon-chat"],
header[role="banner"] [class*="icon-vote"] {
  margin: 0;
}


// Menu Button
header[role="banner"] .btn-menu {
  display: block;
  height: 40px;
  margin-top: 1em;
  width: 50px;
  background: transparent;
  @include mq(min, 444px) {
    margin-top: initial;
  }
  @include mq(min, $max-mq) {
    display: none;
  }
}


// Logo
header[role="banner"] img[alt*="zone"] {
  display: none;

  @include mq(min, $max-mq) {
    display: inline-block;
    transform: scale(0.8) translateX(0) translateY(0);
  }

  @include mq(min, $max-w-lg) {
    display: inline-block;
    transform: scale(0.8) translateX(-70px) translateY(-100px);
  }

}


// Search Form
header[role="banner"] form[role="search"] {
  display: none;
  @include mq(min, $max-mq) {
    display: inherit;
    transform: translateX(-10px);
  }
}


header[role="banner"] form[role="search"] .icon {
  @include size(30px, 30px);
  padding: 5px;
}


// Search Input
header[role="banner"] input[type="search"] {
  padding-top: rems(5);
  padding-bottom: rems(5);
  background: white;
}


header[role="banner"] input[type="search"] + [class*="btn"] {
  border: 2px solid white;
}


// Data Report Card
header[role="banner"] .report-card {
  @include mq(max, $pre-max-w-lg) {
    margin:auto;
    padding: 0.25em;
  }

  @include mq(min, $max-w-lg) {
    flex: 3 1 100%;
  }
}


header[role="banner"] .report__title {
  @include mq(max, $pre-max-w-lg) {
    float: none !important;
    transform: none !important;
    text-align: center;
  }
}


// Logo
header[role="banner"] a[href="/"] {
  @include mq(max, $max-mq) {
    display: none;
  }
}


// Login Button
header[role="banner"] .btn--secondary {
  @include mq(max, $pre-max-w-lg) {
    display: none;
  }

  @include mq(min, $max-w-lg) {
    margin-left: 10px;
    max-width: 12.5em;// 200px
    padding: rems($gutter / 3.5);
  }
}
