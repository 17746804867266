// @requires
// vars/_global.scss
// functions/_img-url.scss
// mixins/_size.scss


//----------------------------------------------------- module

[class*="icon-ask"],
[class*="icon-chat"],
[class*="icon-vote"] {
  @include size(145px, 54px);
  margin: rems(10, $font-base);
  transition: none;
  background-image: img-url("sprite-raster.png");
  background-repeat: no-repeat;
}



//----------------------------------------------------- img placement

@each $key, $value in $student-card-icons {
  .icon-#{$key}-#{nth($value, 1)} {
    background-position: #{nth($value, 2)};
    &:hover,
    &:focus {
      background-position: #{nth($value, 3)};
    }
  }
}


@each $key, $value in $navbg {
  .icon-#{$key}-#{nth($value, 1)} {
    @include size(126px, 47px);
    background-position: #{nth($value, 2)};
  }
}
