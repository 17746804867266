// Content Body
// ==========================================================================

.template-primary main > .container {
  padding: rems($gutter/1.5);
  background: $content-bg;
  @include mq(min, $max-w-lg) {
    padding: rems($gutter * 1.5) rems($gutter) rems($gutter * 6);
  }
}



// Modules
// ==========================================================================
