input:not([type="submit"]),
select,
textarea {
  appearance: none;
  border: none;
  border-radius: 0;
  padding: 1rem;
  width: 100%;
  background: $input-bg;
  &:hover,
  &:focus {
    outline: none;
  }
}


input[type="checkbox"] {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  appearance: checkbox !important;
  width: auto;
}


input[type="radio"] {
  -webkit-appearance: radio !important;
  -moz-appearance: radio !important;
  appearance: radio !important;
  width: auto;
}


input[type="text"]:required {
  border: 2px solid #DD5E00;
}


$select-arrow: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjgzIDE2LjQybDkuMTcgOS4xNyA5LjE3LTkuMTcgMi44MyAyLjgzLTEyIDEyLTEyLTEyeiIvPjxwYXRoIGQ9Ik0wLS43NWg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+';
$select-size: 40%;

select {
  $spacing: 10px;
  cursor: pointer;
  color: #AAAAAA;

  // Allows us to use a custom arrow and avoid
  // the browsers that don't support it.
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    background-image: url(#{$select-arrow});
    background-position: right 1.2% top 50%; // better way to background-position
    background-repeat: no-repeat;
    background-size: 30px;
  }
}


.login input:not([type="submit"]),
.login textarea,
.login select {
  margin-bottom: 1rem;
}
