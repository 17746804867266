// @requires
// functions/rems
// functions/context-calc

//----------------------------------------------------- module

.report {
  @extend .material-shadow;
  @include mq(max, $pre-max-w-lg) {
    padding: 1.5em 1em;
    background: rgba(255, 255, 255, 0.65);
  }
  @include mq(min, $max-w-lg) {
    box-shadow: none !important;
  }
}


.report-card {
  max-width: 700px;
  min-height: 90px;
}


.report__data a {
  &:hover {
    cursor: pointer;
    .avatar {
      border-color: $secondary-col;
    }

    .media-obj__body {
      color: black;
    }
  }
}



//----------------------------------------------------- data

.report__title {
  float: left;
  margin-bottom: context-calc(10, 16, em);
  transform: translateY(150%);
  font-size: context-calc(20, 16, em);
  text-transform: uppercase;
  text-align: right;
}


.report__data {
  display: flex;
  align-items: center;
  align-content: stretch;
  height: 100%;
  @include mq(max, 520px) {
    flex-wrap: wrap;
  }
}



//----------------------------------------------------- media & caption

.report__data .media-obj {
  align-items: center;
  margin: 0;
}


// .report__data .media-obj--caption {
.report__data > a {
  flex: 33.333333%;
  padding: 0 0.25em;
  color: inherit;
  @include mq(min, $max-w-lg) {
    flex: 70px;
    padding: initial;
  }
}


.report__data .media-obj__body {
  font-weight: 800;
}
