// 2/3 Grid Contexts
// ==========================================================================

// child rows and sibling
.grid.two-thirds > .row + .row {
  @include mq(min, $max-w-lg) {
    margin-top: rems($gutter);
  }
}



// 1/3 Grid Contexts
// ==========================================================================

// separator rule
.grid.one-third hr {
  border-top: 2px solid #8E6F9C;
  margin-top: rems($gutter * 2);
  margin-bottom: rems($gutter * 2);
}


// child rows and sibling
.grid.one-third > .row + .row {
  margin-top: rems($gutter);
}


// Media Object Cards
.grid.one-third .card.media-obj {
  @include mq(minmax, 740px, 968px) {
    flex-direction: column;
  }

  @media screen and (max-width: $max-w-sm) {
    flex-direction: column;
  }
}


// Speech Bubbles
.grid.one-third .speech-bubble {
  display: flex;
  align-content: stretch;
  padding: 0.125em 1.25em;
  @include mq(min, $screen-med) {
    min-height: 12.5em; // 200px
    max-height: 12.5em; // 200px
  }
}


// IE10 Latest : Windows 8
.flexboxtweener .grid.one-third .speech-bubble {
  display: block;
}
