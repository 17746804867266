//----------------------------------------------------- module

footer[role="contentinfo"] {
  padding: rems($gutter) 1em;
  text-align: center;
  @include mq(min, $medium) {
    text-align: left;
  }
}



//----------------------------------------------------- left side

footer[role="contentinfo"] .grid.three-quarters {
  @include mq(min, $medium) {
    transform: translateY(-70px);
  }
}



//----------------------------------------------------- navigation

// == component
footer[role="contentinfo"] nav {
  padding-top: rems($gutter);
  font-size: 1em;
}


// == links
footer[role="contentinfo"] nav a {
  display: inline-block;
  padding: .5em;
  color: $main-color;

  &:not(:last-of-type) {
    padding-right: rems($gutter);
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $main-color;
  }
}
