// ==========================================================================
//    Base styles: opinionated defaults
// ==========================================================================
// HTML5 Boilerplate
//
// What follows is the result of much research on cross-browser styling.
// Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
// Kroc Camen, and the H5BP dev community and team.

button,
input,
select,
textarea {
	color: #222;
}

// Remove text-shadow in selection highlight: h5bp.com/i
// These selection rule sets have to be separate.
// Customize the background color to match your design.
::-moz-selection {
	text-shadow: none;
	background: $selection-highlight-color;
	color: white;
}

::selection {
	text-shadow: none;
	background: $selection-highlight-color;
	color: white;
}


// A better looking default horizontal rule
hr {
	border: 0;
	border-top: 1px solid #ccc;
	box-shadow: inset 0 1px 1px rgba(255, 251, 251, 0.76);
	display: block;
	height: 1px;
	margin: 1em 0;
	padding: 0;
}


// Remove the gap between images, videos, audio and canvas and the bottom of
// their containers: h5bp.com/i/440
audio,
canvas,
img,
video {
	vertical-align: middle;
}


// Remove default fieldset styles.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}


// Allow only vertical resizing of textareas.
textarea {
	resize: vertical;
}


// ============================================================
// $Custom Base Styles
// ============================================================

html,
input {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
    background: $banner-bg;
}

body {
    background: $banner-bg img-url($bg-image) -100% 0 $bg-repeat;
    background-position: $bg-left-pos $bg-top-pos;
    min-width: 320px;
}

@include placeholder-styles {
	color: $placeholder-color;
}

img,
embed,
object,
video {
	max-width: 100%;
	height: auto;
}

svg {
	width: 100%;
	height: auto;
  &:not(:root) {
    overflow: visible;
  }
}

a {
	transition: 0.275s all ease-in-out;
	color: $global-link-color;
	text-decoration: none;

	&:focus,
	&:hover,
	&:active {
		// color: lighten($global-link-color, 30%);
        color: black;
	}
}
