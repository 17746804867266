.row {
  @extend .clearfix;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  // @include mq(min, $screen-med) {
  //   padding-right: $grid-gap;
  //   padding-left: $grid-gap;
  // }
}


.row.no-grid {
  padding-right: $grid-gap * 2;
  padding-left: $grid-gap * 2;
  @include mq(min, $screen-med) {
    padding-right: $grid-gap;
    padding-left: $grid-gap;
  }
}


.grid {
  float: none;
  margin-bottom: $grid-gap;
  @include mq(min, $screen-med) {
    float: left;
    margin-bottom: $grid-gap - $grid-gap;
    position: relative;
    padding-right: $grid-gap;
    padding-left: $grid-gap;
  }
}


.grid.full {
  width: 100%;
}


.grid.half {
  width: 100%;
  @include mq(min, $screen-med) {
    width: 50%;
  }
}


.grid.one-third {
  width: 100%;
  @include mq(min, $screen-med) {
    width: (100% / 3);
  }
}


.grid.two-thirds {
  width: 100%;
  @include mq(min, $screen-med) {
    width: ((100% / 3) * 2);
  }
}


.grid.one-quarter {
  width: 100%;
  @include mq(min, $screen-med) {
    width: 25%;
  }
}


.grid.three-quarters {
  width: 100%;
  @include mq(min, $screen-med) {
    width: 75%;
  }
}
