// Flexbox on Steroids
// http://codepen.io/dennisgaebel/pen/LVvqOx

.sticky-body {
  display: flex;
  flex-direction: column;
}

.sticky-container {
  display: table;
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 100%;
  width: 100%;

  @include mq(min, $max-w-lg) {
    flex: 1 0 auto;
  }
}

.sticky-row {
  display: -moz-box; // As of FF42
}

.sticky-header {
  flex: none;
}

.sticky-content {
  flex: 1 0 auto;
  height: auto;
}

.sticky-footer {
  flex: none;
  vertical-align: middle;
  align-items: center;
}
