// large
.tinynav {
  display: none;
}


// small
@media screen and (max-width: 768px) {
  .tinynav {
    display: block;
  }

  .select-nav {
    display: none;
  }
}
